import {
  GET_HEALTH_PROMPTS,
  GET_HEALTH_PROMPTS_SUCCESS,
  GET_HEALTH_PROMPTS_FAILURE,
  GET_HEALTH_PROMPTS_RAW_SUCCESS,
  //
  TOGGLE_HEALTH_PROMPTS_SMS,
  TOGGLE_HEALTH_PROMPTS_SMS_SUCCESS,
  TOGGLE_HEALTH_PROMPTS_SMS_FAILURE,
  //
  SET_HEALTH_PROMPT_ACTION,
  SET_HEALTH_PROMPT_ACTION_SUCCESS,
  SET_HEALTH_PROMPT_ACTION_FAILURE,
  //
  SEND_HEALTH_PROMPT_HISTORY_EMAIL,
  SEND_HEALTH_PROMPT_HISTORY_EMAIL_SUCCESS,
  SEND_HEALTH_PROMPT_HISTORY_EMAIL_FAILURE,
  //
  GET_HEALTH_PROMPT_LABOR_CSV,
  GET_HEALTH_PROMPT_LABOR_CSV_SUCCESS,
  GET_HEALTH_PROMPT_LABOR_CSV_FAILURE,
  GET_HEALTH_PROMPT_LABOR_CSV_RESET,
  //
  POST_FEEDBACK_PROMPTS,
  POST_FEEDBACK_PROMPTS_SUCCESS,
  POST_FEEDBACK_PROMPTS_FAILURE,
  //
  RESET_FEEDBACK_FORM
} from '../actions';

const defaultArrayValue = [];

const initialState = {
  feedback: null,
  healthPrompts: null,
  healthPromptsRaw: null,
  loading: false,
  atHomeSMS: false,
  error: false,
  historyEmailSent: false,
  healthPromptEmailSent: false,
  laborReportRaw: null,
  message: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_FEEDBACK_PROMPTS:
      return { ...state, loading: true, error: false, message: false };
    case POST_FEEDBACK_PROMPTS_SUCCESS:
      return { ...state, feedback: action.payload, loading: false, error: false, message: true };
    case POST_FEEDBACK_PROMPTS_FAILURE:
      return { ...state, feedback: {}, loading: false, error: true, message: false };
    //
    case RESET_FEEDBACK_FORM:
      return { ...state, loading: false, error: false, message: false };
    //
    case GET_HEALTH_PROMPTS:
      return { ...state, loading: true, error: false, message: false };
    case GET_HEALTH_PROMPTS_RAW_SUCCESS:
      return { ...state, healthPromptsRaw: action.payload, loading: false, error: false };
    case GET_HEALTH_PROMPTS_SUCCESS:
      return { ...state, healthPrompts: action.payload, loading: false, error: false };
    case GET_HEALTH_PROMPTS_FAILURE:
      return { ...state, healthPrompts: defaultArrayValue, loading: false, error: true };
    //
    case TOGGLE_HEALTH_PROMPTS_SMS:
      return { ...state, loading: true, error: false };
    case TOGGLE_HEALTH_PROMPTS_SMS_SUCCESS:
      return { ...state, atHomeSMS: action.payload, loading: false, error: false };
    case TOGGLE_HEALTH_PROMPTS_SMS_FAILURE:
      return { ...state, loading: false, atHomeSMS: action.payload, error: true };
    //
    case SET_HEALTH_PROMPT_ACTION:
      return { ...state, loading: true, error: false };
    case SET_HEALTH_PROMPT_ACTION_SUCCESS:
      return { ...state, loading: false, error: false };
    case SET_HEALTH_PROMPT_ACTION_FAILURE:
      return { ...state, loading: false, error: true };
    //
    case SEND_HEALTH_PROMPT_HISTORY_EMAIL:
      return { ...state, loading: true, error: false };
    case SEND_HEALTH_PROMPT_HISTORY_EMAIL_SUCCESS:
      return { ...state, loading: false, error: false };
    case SEND_HEALTH_PROMPT_HISTORY_EMAIL_FAILURE:
      return { ...state, loading: false, error: true };
    //
    case GET_HEALTH_PROMPT_LABOR_CSV:
      return { ...state, loading: true, error: false };
    case GET_HEALTH_PROMPT_LABOR_CSV_SUCCESS:
      const csv = action.payload.csvData;
      const locationNumber = action.payload.locationNumber;
      return { ...state, laborReportRaw: csv, locationNumber: locationNumber, loading: false, error: false };
    case GET_HEALTH_PROMPT_LABOR_CSV_FAILURE:
      return { ...state, loading: false, laborReportRaw: action.payload, error: true };
    case GET_HEALTH_PROMPT_LABOR_CSV_RESET:
      return { ...state, loading: false, laborReportRaw: null, error: false };
    //
    default:
      return state;
  }
}
