import {
  GET_HEALTH_PROMPTS,
  GET_HEALTH_PROMPTS_SUCCESS,
  GET_HEALTH_PROMPTS_FAILURE,
  GET_HEALTH_PROMPTS_RAW_SUCCESS,
  //
  TOGGLE_HEALTH_PROMPTS_SMS,
  TOGGLE_HEALTH_PROMPTS_SMS_SUCCESS,
  TOGGLE_HEALTH_PROMPTS_SMS_FAILURE,
  //
  SET_HEALTH_PROMPT_ACTION,
  SET_HEALTH_PROMPT_ACTION_SUCCESS,
  SET_HEALTH_PROMPT_ACTION_FAILURE,
  //
  SEND_HEALTH_PROMPT_HISTORY_EMAIL,
  SEND_HEALTH_PROMPT_HISTORY_EMAIL_SUCCESS,
  SEND_HEALTH_PROMPT_HISTORY_EMAIL_FAILURE,
  //
  GET_HEALTH_PROMPT_LABOR_CSV,
  GET_HEALTH_PROMPT_LABOR_CSV_SUCCESS,
  GET_HEALTH_PROMPT_LABOR_CSV_FAILURE,
  GET_HEALTH_PROMPT_LABOR_CSV_RESET,
  //
  POST_FEEDBACK_PROMPTS,
  POST_FEEDBACK_PROMPTS_SUCCESS,
  POST_FEEDBACK_PROMPTS_FAILURE,
  //
  RESET_FEEDBACK_FORM
} from '../actions';
import { API_GET, API_DEL, API_POST, API_PATCH, checkSameDay, formatReverseDate, API_PUT } from '../utils';
import { showSuccessNotification } from '../utils';

const TMHP_API_URL = process.env.REACT_APP_TMHP_API_URL;
const TMS_API_URL = process.env.REACT_APP_TMS_API_URL;
const FEEDBACK_API_URL = process.env.REACT_APP_FEEDBACK_API_URL;

export const getHealthPromptsByLocation = (locationNumber) => {
  return async dispatch => {
    dispatch({ type: GET_HEALTH_PROMPTS });

    // Assemble URL
    const HEALTH_PROMPT_URL = `${TMHP_API_URL}/operator/health-prompt?locationNumber=${locationNumber}`

    try {
      const { data } = await API_GET(HEALTH_PROMPT_URL);
      // Group Alert events by date
      const parsedData = parseHealthPromptData(data);

      // Separate into today and history
      const combinedData = separateHistoryData(parsedData);

      // Structure into csv format
      const csvData = restructureHealthPromptData(data.health_prompts);

      // Update Health Prompts data
      dispatch({ type: GET_HEALTH_PROMPTS_SUCCESS, payload: combinedData });
      // Dispatch, specifically, to update the atHomeSMS variable
      dispatch({ type: TOGGLE_HEALTH_PROMPTS_SMS_SUCCESS, payload: combinedData.atHomeSMS })
      // Update Raw data
      dispatch({ type: GET_HEALTH_PROMPTS_RAW_SUCCESS, payload: csvData,})
    } catch (err) {
      dispatch({ type: GET_HEALTH_PROMPTS_FAILURE, payload: err });
    }
  }
}

export const postFeedback = (body) => {
  return async (dispatch, getState) => {

    // Get Location from Redux
    const locationNumber = await getState().location.selectedLocation;

    // Add necessary properties
    body['location_number'] = locationNumber;
    body['source'] = window.location.href;

    dispatch({ type: POST_FEEDBACK_PROMPTS });

    // Assemble URL
    const FEEDBACK_POST_URL = `${FEEDBACK_API_URL}/feedback`

    try {
      const { data } = await API_POST(FEEDBACK_POST_URL, body);
      dispatch({ type: POST_FEEDBACK_PROMPTS_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: POST_FEEDBACK_PROMPTS_FAILURE, payload: err });
    }
  }
}

export const resetFeedbackForm = () => {
  return dispatch => {
    dispatch({ type: RESET_FEEDBACK_FORM });
  }
}

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const parseHealthPromptData = (healthPromptData) => {
  let atHomeUrl = healthPromptData['at_home']['url'];
  let atHomeSMS = healthPromptData['at_home']['sms_opt'];
  let hasScheduleData = healthPromptData['at_home']['sms_data'];
  let raw_data = healthPromptData['health_prompts'];
  let temp_data = {};
  raw_data.forEach(element => {
    let year = parseInt(element.end_time.substring(0, 4));
    let month = parseInt(element.end_time.substring(5, 7)) - 1;
    let day = parseInt(element.end_time.substring(8, 10));
    let hours = parseInt(element.end_time.substring(11, 13));
    let minutes = parseInt(element.end_time.substring(14, 16));
    let date = new Date(year, month, day, hours, minutes);
    let date_string = `${monthNames[date.getMonth()]} ${date.getDate()}`;
    let time_string = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    let name = !!element.employee_name ? element.employee_name : "Unknown";
    let uuid = element.uuid ? element.uuid : null;
    let action = element.action ? element.action : null;
    let at_home = element.at_home;
    let verified = false;

    if (at_home) {
      verified = at_home.verified;
    }

    if (date_string in temp_data) {
      // If the day already exists in the dictionary, then add the time to the day's collection of times in the correct order.
      let placed = false;
      for (let i = 0; i < temp_data[date_string]['times'].length; i++) {
        if (temp_data[date_string]['times'][i]['time'] < date) {
          temp_data[date_string]['times'].splice(i, 0, { "time": date, "time_string": time_string, "name": name, "uuid": uuid, "action": action, "at_home": at_home, "verified": verified });
          placed = true;
          break;
        }
      }

      // If the day already exists in the dictionary and the time is the most recent time in the day's collection, then
      // add it as the first item in the collection.
      if (!placed) {
        temp_data[date_string]['times'].push({ "time": date, "time_string": time_string, "name": name, "uuid": uuid, "action": action, "at_home": at_home, "verified": verified });
      }

    } else {
      // Else, if the day does NOT already exist in the dictionary, add the day and the time to the dictionary.
      temp_data[date_string] = {};
      temp_data[date_string]['date'] = date;
      temp_data[date_string]['date_string'] = date_string;
      temp_data[date_string]['times'] = [{ "time": date, "time_string": time_string, "name": name, "uuid": uuid, "action": action, "at_home": at_home, "verified": verified }];
    }
  });

  let formattedData = {
    alertData: Object.values(temp_data).sort(function (a, b) { return b['date'] - a['date'] }),
    details: {
      atHomeUrl,
      atHomeSMS,
      hasScheduleData
    }
  }

  return formattedData;
}

const separateHistoryData = (data) => {
  const { alertData } = data;
  // Separate today and history into separate object properties
  // Ensure alertData has length
  const today = (alertData.length && checkSameDay(alertData[0].date)) ? [alertData[0]] : [];
  const history = today.length ? alertData.slice(1) : alertData;

  // Re-assemble with today, history, and details
  const formattedData = {
    ...data.details,
    today,
    history
  }

  return formattedData;
}

export const toggleAtHomeSMSOptions = (smsOptions) => {
  return async (dispatch, getState) => {
    dispatch({ type: TOGGLE_HEALTH_PROMPTS_SMS });

    // Get Location from Redux
    const locationNumber = await getState().location.selectedLocation;

    // Assemble URL
    const HEALTH_PROMPT_SMS_URL = `${TMHP_API_URL}/operator/health-prompt/sms_opt?locationNumber=${locationNumber}`

    try {
      // Based on boolean smsOptions, use DELETE or POST
      if (smsOptions) {
        await API_DEL(HEALTH_PROMPT_SMS_URL);
      } else {
        await API_POST(HEALTH_PROMPT_SMS_URL, null)
      }
      dispatch({ type: TOGGLE_HEALTH_PROMPTS_SMS_SUCCESS, payload: !smsOptions });
      showSuccessNotification('SMS Settings Updated');
    } catch (err) {
      dispatch({ type: TOGGLE_HEALTH_PROMPTS_SMS_FAILURE, payload: smsOptions });
      console.log(err);
    }
  }
}

export const updateHealthPromptAction = (uuid, action) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_HEALTH_PROMPT_ACTION });

    // Get Location from Redux
    const locationNumber = await getState().location.selectedLocation;

    // Assemble URL
    const HEALTH_PROMPT_ACTION_URL = `${TMS_API_URL}/operator/operator-action`

    const body = { uuid, 'operatorAction':action };

    try {
      await API_PUT(HEALTH_PROMPT_ACTION_URL, body);
      dispatch({ type: SET_HEALTH_PROMPT_ACTION_SUCCESS });
      // Now return action so individual card can be updated without re-rendering
      return action;
    } catch (err) {      
      dispatch({ type: SET_HEALTH_PROMPT_ACTION_FAILURE, payload: err });
    }
  }
}

export const sendHealthPromptHistoryEmail = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SEND_HEALTH_PROMPT_HISTORY_EMAIL });

    // Get Location from Redux
    const locationNumber = await getState().location.selectedLocation;

    // Assemble URL
    const HEALTH_PROMPT_HISTORY_EMAIL_URL = `${TMHP_API_URL}/operator/health-prompt/email?locationNumber=${locationNumber}`;

    try {
      await API_POST(HEALTH_PROMPT_HISTORY_EMAIL_URL, null);
      dispatch({ type: SEND_HEALTH_PROMPT_HISTORY_EMAIL_SUCCESS });
      // Display success notification
      showSuccessNotification('90 Day History Email Sent!');
    } catch (err) {
      dispatch({ type: SEND_HEALTH_PROMPT_HISTORY_EMAIL_FAILURE, payload: err });
      console.log(err);
    }
  }
}

export const resetHealthPromptLaborReport = () => {
  return async (dispatch) => {
    dispatch({ type: GET_HEALTH_PROMPT_LABOR_CSV_RESET});
  }
}

export const getHealthPromptLaborReport = (startDate, endDate) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_HEALTH_PROMPT_LABOR_CSV });

    // Get Location from Redux
    const locationNumber = await getState().location.selectedLocation;

    const startTime = formatReverseDate(startDate);
    const endTime = formatReverseDate(endDate);

    // Assemble URL
    const HEALTH_PROMPT_LABOR_EMAIL_URL = `${TMHP_API_URL}/operator/health-prompt/labor-email`
    const BODY = `{\n    "locationNumber":"${locationNumber}",\n    "startTime":"${startTime}",\n    "endTime":"${endTime}"\n}`
    try {
      const { data } = await API_POST(HEALTH_PROMPT_LABOR_EMAIL_URL, BODY);
      const csvData = restructureHealthPromptLaborData(data);
      dispatch({ type: GET_HEALTH_PROMPT_LABOR_CSV_SUCCESS, payload: { csvData, locationNumber }});
      showSuccessNotification(csvData.length === 0 ? 'No Labor Report Data Found' : 'Labor Report CSV Found!');
    } catch (err) {
      dispatch({ type: GET_HEALTH_PROMPT_LABOR_CSV_FAILURE, payload: err });
      console.log(err);
    }
  }
}

const restructureHealthPromptData = (healthPromptData) => {
  return healthPromptData.map((record) => {

    let newObj = {};
    newObj["Employee"] = record.employee_name;
    newObj["Location"] = record.location_number;
    newObj["Action"] = record.action ? record.action : "TBD";

    let year = parseInt(record.end_time.substring(0, 4));
    let month = parseInt(record.end_time.substring(5, 7)) - 1;
    let day = parseInt(record.end_time.substring(8, 10));
    let hours = parseInt(record.end_time.substring(11, 13));
    let minutes = parseInt(record.end_time.substring(14, 16));
    let date = new Date(year, month, day, hours, minutes);
    let date_string = `${monthNames[date.getMonth()]} ${date.getDate()}`;
    let time_string = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    newObj["Clock In Time"] = date_string + " " + year + " at " + time_string;

    newObj["date"] =  date;

    return newObj;
  });
}

const restructureHealthPromptLaborData = (healthPromptLaborData) => {
  return healthPromptLaborData.map((record) => {

    let newObj = {};
    newObj["Team Member First Name"] = record.firstname;
    newObj["Team Member Last Name"] = record.lastname;
    newObj["Number of Surveys Completed"] = record.count;
    newObj["Actual Time Spent (hours)"] = record.hours;

    return newObj;
  });
}
