import React, { Component } from 'react';
import { connect } from 'react-redux';
import I18n from '../../../i18n/utils';
import './Home.scss';
import { Container, Row } from 'react-bootstrap';
import { hasAuthToken } from '../../utils';
import TodayPage from './TodayPage/TodayPage';
import HistoryPage from './HistoryPage/HistoryPage';
import { TabButtons } from '../../components/common';
import queryString from 'query-string';
import { setSelectedLocation, getHealthPromptsByLocation, postFeedback } from '../../actions';
import CFASpinner from '../../components/Spinner/CFASpinner';
import ToolTip from 'react-portal-tooltip';

import FeedbackModal from '../../components/common/FeedbackModal/FeedbackModal';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      selectedLocation: null,
      showHistoryTab: false,
      healthPrompts: null,
      loading: false,
      showFeedbackModal: false,
      showFeedbackTooltip: true,
    };

    this.toggleShowHistoryTab = this.toggleShowHistoryTab.bind(this);
    this.toggleShowFeedbackTab = this.toggleShowFeedbackTab.bind(this);
    this.autoHideFeedbackTooltip = this.autoHideFeedbackTooltip.bind(this);
    this.closeFeedbackModal = this.closeFeedbackModal.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let update = {};

    // Update Selected Location
    if (props.selectedLocation !== state.selectedLocation) {
      update.selectedLocation = props.selectedLocation;
    }

    // Update Health Prompts
    if (props.healthPrompts !== state.healthPrompts) {
      update.healthPrompts = props.healthPrompts;
    }

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    return update;
  }

  componentDidMount() {
    const { locationNumber } = queryString.parse(this.props.location.search);

    if (locationNumber !== this.state.selectedLocation) {
      this.props.setSelectedLocation(locationNumber);
      this.props.getHealthPromptsByLocation(locationNumber);
    }

    this.autoHideFeedbackTooltip();
  }

  toggleShowHistoryTab() {
    const showHistoryTab = !this.state.showHistoryTab;
    this.setState({ showHistoryTab });
  }

  toggleShowFeedbackTab() {
    const showFeedbackModal = !this.state.showFeedbackModal;
    this.setState({ showFeedbackModal });
  }

  closeFeedbackModal() {
    this.setState({ showFeedbackModal: false });
  }

  autoHideFeedbackTooltip() {
    // Hide Tooltip after 3 seconds
    setTimeout(() => {
      this.setState({ showFeedbackTooltip: false });
    }, 3000);
  }

  render() {
    return (
      <div className="Home">
        {
          !hasAuthToken() ?
            <h1>{I18n.t('APP_UNAUTHORIZED_MESSAGE')}</h1>
            :
            <>
              {
                this.state.healthPrompts == null ?
                  <CFASpinner loading={true} margin />
                  :
                  <>
                    {
                      this.state.selectedLocation == null ?
                        <h3 className="location-search">{I18n.t('APP_UNDEFINED_LOCATION_MESSAGE')}</h3> :
                        <Row className="tab-buttons-row">
                          <TabButtons
                            className="TabButtons"
                            tabText1={I18n.t('APP_TODAY_TAB')}
                            tabText2={I18n.t('APP_HISTORY_TAB')}
                            selectedTabIndex={this.state.showHistoryTab ? 1 : 0}
                            onClick={this.toggleShowHistoryTab}
                          />
                          {
                            !this.state.showHistoryTab &&
                            <>
                              <img
                                alt=""
                                id="show-feedback-button"
                                src={require('../../../assets/feedback.svg').default}
                                className="feedback-icon"
                                onClick={this.toggleShowFeedbackTab}
                              />
                              {
                                this.state.showFeedbackTooltip &&
                                <ToolTip
                                  active={true}
                                  position="left"
                                  align="left"
                                  arrow="right"
                                  parent="#show-feedback-button"
                                  className="feedback-tooltip-content"
                                >
                                  <div>
                                    <h3 className="feedback-tooltip-message">{I18n.t('HOME_PAGE_FEEDBACK_TOOLTIP')}</h3>
                                  </div>
                                </ToolTip>
                              }
                            </>
                          }
                          {
                            this.state.showFeedbackModal &&
                            <FeedbackModal
                              type="sms-info-modal"
                              onClose={this.closeFeedbackModal}
                            />
                          }
                          <Container>
                            <>
                              {
                                !this.state.showHistoryTab ?
                                  <TodayPage />
                                  :
                                  <HistoryPage />
                              }
                            </>
                          </Container>
                        </Row>
                    }
                  </>
              }
            </>
        }
      </div>
    )
  }
};

const mapStateToProps = state => {
  const { selectedLocation } = state.location;
  const { loading, healthPrompts } = state.healthPrompt;
  return { selectedLocation, loading, healthPrompts };
}

export default connect(
  mapStateToProps,
  {
    setSelectedLocation,
    getHealthPromptsByLocation,
    postFeedback
  }
)(Home);